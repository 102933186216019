import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import pvInstallerColumns from './columns/pvInstallerColumns';
import oemEmployeeColumns from './columns/oemEmployeeColumns';
import { UserInfoSub } from '../../components/ReactTable/renderFunctions';
import { CompanyInfo } from './components';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import { SearchField, TopBar, Pagination } from '../../components';
import {
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  DELETE_DATA_MODAL_ID,
  ADD_PV_INSTALLER_TO_OEM_MODAL_ID,
  ADD_EMPLOYEE_MODAL_ID,
  REMOVE_DATA_MODAL_ID
} from '../ModalWindow/slice';
import {
  COMPANY_DATALIST_ID,
  getConnectedInstallersRequestData,
  GET_OEM_EMPLOYEES_PARAMS
} from './constants';
import { dataListReloadData } from '../DataList/slice';
import { getAllConnectedInstallers } from './slice';
import { AddPVInstallerToOEMWindow, RemoveOEMWindow } from './modals';

import { configSavedSettings } from '../../utils';
import {
  getDataListCurrent_companyData_IdSelector,
  getDataListCurrent_companyDataOem_IdSelector
} from '../../redux-store/selectors/dataList';
import { OEM_EMPLOYEES_URL, OEM_PV_INSTALLER_URL } from '../../api/apiUrls';

import i18n from '../../i18n';
import { AddEmployeeModalWindow, ConfirmDeleteUserWindow } from '../../modals';
import { transformResponse } from '../../utils/transformResponse';
import { DeleteEmployeeWindow } from '../Employees/modals';

class PVInstallersDetails extends PureComponent {
  constructor(props) {
    super(props);
    const { myRoleType, history } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.pvInstallerColumns = pvInstallerColumns(myRoleType);
    this.oemEmployeeColumns = oemEmployeeColumns(myRoleType);

    configSavedSettings(this, 'company-details', {
      expander: true,
      subTabs: true,
      defaultParams: {
        _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
        _sort: 'my_company:asc'
      },
      pathName,
      historyKey
    });
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  render() {
    const { expanded, activeTab } = this.state;
    const {
      myRoleType,
      match,
      openModalWindow: openModal,
      closeModalWindow: closeModal,
      modalWindowSend: modalSend,
      companyOemId,
      supportOemId
    } = this.props;

    const { companyID } = match.params;
    const companyDataListURL = `/oem/${companyID}`;
    const dataListIDUsers = 'company_sm_oem';
    const dataListIdOemEmployees = 'oem-employees-data-list-id';

    const { GET_CONNECTED_INSTALLERS_URL, GET_CONNECTED_INSTALLERS_PARAMS } = getConnectedInstallersRequestData({ myRoleType, supportOemId, companyOemId });

    const sendAddInstallers = (fields) => {
      modalSend({
        modalID: ADD_PV_INSTALLER_TO_OEM_MODAL_ID,
        requestConfig: {
          method: 'put',
          url: `${OEM_PV_INSTALLER_URL}/${companyOemId}`,
          data: { pvInstallerIds: fields }
        },
        cb: () => dataListReloadData({ listID: dataListIDUsers })
      });
    };

    const sendRemoveUser = ({ id }) => modalSend({
      modalID: DELETE_DATA_MODAL_ID,
      requestConfig: {
        method: 'delete',
        url: `${OEM_PV_INSTALLER_URL}/${companyOemId}?pv-installer_id=${id}`
      },
      cb: () => dataListReloadData({ listID: dataListIDUsers })
    });

    const addOemEmployee = (fields) => modalSend({
      modalID: ADD_EMPLOYEE_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: OEM_EMPLOYEES_URL,
        data: { ...fields, oem: supportOemId }
      },
      cb: () => dataListReloadData({ listID: dataListIdOemEmployees })
    });

    const removeOemEmployee = ({ id }) => modalSend({
      modalID: REMOVE_DATA_MODAL_ID,
      requestConfig: {
        method: 'delete',
        url: `user/${id}`
      },
      cb: () => dataListReloadData({ listID: dataListIdOemEmployees })
    });

    const expandedChangeHandler = (all) => {
      this.saveExpanderChanges(all);
    };

    const subTabs = ['pv_installer-tab', 'oem_employees-tab'];
    let activeTabIndex = subTabs.indexOf(activeTab);
    if (activeTabIndex === -1) activeTabIndex = 0;

    return (
      <>
        <TopBar showNavigation />
        <DataList
          listID={COMPANY_DATALIST_ID}
          listURL={companyDataListURL}
          Component={CompanyInfo}
        />

        {supportOemId && (
          <div className="container-fluid pv-inst-detail-wrapper">
            <div className="d-flex control-panel">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10">
                <DataListFilter
                  Component={SearchField}
                  componentProps={{
                    searchByFields: 'sm_id,email,first_name,last_name,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={activeTab === 'pv_installer-tab' ? dataListIDUsers : dataListIdOemEmployees}
                />
              </div>
            </div>
            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(index) => this.changeSubTab(subTabs[index])}
            >
              <TabList>
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px">
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('pvInstallers')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px">
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('employees')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </TabList>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  {myRoleType !== 'viewer' && (
                    <div className="OEMAddBtn">
                      <a
                        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                        onClick={openModal.bind(null, {
                          modalID: ADD_PV_INSTALLER_TO_OEM_MODAL_ID,
                          data: null
                        })}
                      >
                        <span>
                          <span>{i18n.t('addPViToOEMtn')}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  <RemoveOEMWindow onSubmit={sendRemoveUser} />
                  <DataList
                    listID={dataListIDUsers}
                    listURL={GET_CONNECTED_INSTALLERS_URL}
                    params={{ ...GET_CONNECTED_INSTALLERS_PARAMS, _limit: 10 }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 10,
                      className: '-highlight m-datatable__table',
                      columns: this.pvInstallerColumns,
                      SubComponent: UserInfoSub,
                      manual: true,
                      onExpandedChange: expandedChangeHandler,
                      expanded
                    }}
                  />
                </div>
                <div className="pb-1">
                  <DataListPagination
                    Component={Pagination}
                    listID={dataListIDUsers}
                    defaultPageSize={10}
                    componentProps={{
                      savePaginationChanges: this.savePaginationChanges
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  {myRoleType !== 'viewer' && (
                    <div className="OEMAddBtn">
                      <a
                        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                        onClick={openModal.bind(null, {
                          modalID: ADD_EMPLOYEE_MODAL_ID,
                          data: { roleName: 'oem_employee' }
                        })}
                      >
                        <span>
                          <span>{i18n.t('addEmployeebtn')}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  <ConfirmDeleteUserWindow userRole={i18n.t('employee').toLowerCase()} />
                  <DeleteEmployeeWindow onSubmit={removeOemEmployee} />

                  <DataList
                    listID={dataListIdOemEmployees}
                    listURL={OEM_EMPLOYEES_URL}
                    params={{ ...GET_OEM_EMPLOYEES_PARAMS, employerOem: supportOemId }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 10,
                      className: '-highlight m-datatable__table',
                      columns: this.oemEmployeeColumns,
                      manual: true,
                      onExpandedChange: expandedChangeHandler,
                      expanded
                    }}
                  />
                </div>
                <div className="pb-1">
                  <DataListPagination
                    Component={Pagination}
                    listID={dataListIdOemEmployees}
                    defaultPageSize={10}
                    componentProps={{
                      savePaginationChanges: this.savePaginationChanges
                    }}
                  />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        )}
        <AddPVInstallerToOEMWindow
          modalID={ADD_PV_INSTALLER_TO_OEM_MODAL_ID}
          handleSubmit={sendAddInstallers}
          companyOemId={companyOemId}
          supportOemId={supportOemId}
          handleOnClose={closeModal.bind(null, {
            modalID: ADD_PV_INSTALLER_TO_OEM_MODAL_ID
          })}
        />
        <AddEmployeeModalWindow initialValues={{ role_type: 'oem_employee' }} onSubmit={addOemEmployee} />
      </>
    );
  }
}

PVInstallersDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  companyOemId: PropTypes.string,
  supportOemId: PropTypes.string,
  history: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = createStructuredSelector({
  companyOemId: getDataListCurrent_companyData_IdSelector,
  supportOemId: getDataListCurrent_companyDataOem_IdSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  closeModalWindow,
  modalWindowSend,
  getAllConnectedInstallers
})(PVInstallersDetails);
