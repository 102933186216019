import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { uniqBy, omit } from 'lodash';

import { PrimaryButton } from '../../components/UIKit';
import CloudStatusWindow from '../CloudStatusWindow';
import ExportDropDownList from './components/ExportDropDownList';
import { SearchField, TopBar, Pagination, Breadcrumbs, Expander, LicenseFilter } from '../../components';
import { DataListPagination, DataListFilterV2, DataListV2 } from '../DataList';
import { MultiFilter, RenderSelectFilter, UpdateSMForm } from './components';
import { AddGatewayWindow, DeleteGatewayWindow, DeleteFirmwareWindow, ConfirmDeleteGatewayWindow } from './modals';
import { ChooseLicenseWindow } from '../../modals';

import {
  openModalWindow, closeModalWindow, modalWindowSend,
  REMOVE_GATEWAY_MODAL_ID, CONFIRM_REMOVE_GATEWAY_MODAL_ID, REMOVE_FIRMWARE_UPDATE_MODAL_ID, CLOUD_STATUS_MODAL_ID
} from '../ModalWindow/slice';
import { dataListReloadData, dataListSendRequest } from '../DataList/slice';
import { saveDevicesTypesList } from '../DevicesTable/slice';
import { devicesGetTypesAndNames } from '../UserDetails/slice';
import { updateCloudStatusMessage, requestCloudStatusMessage } from '../CloudStatusWindow/slice';
import { installedSMReplaceSelected, installedSMSendRequest, instSMGetFWVersions } from './slice';
import { filterOnUndefined } from '../DataList/utils';

import { getSignInUserSelector } from '../../redux-store/selectors/signIn';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { getCloudStatusMessagesSelector } from '../../redux-store/selectors/cloudStatus';
import { getUserDevicesDevicesListSelector } from '../../redux-store/selectors/userDevices';
import { getDataListSelector, getDataListInstalled_smParamsSelector } from '../../redux-store/selectors/dataList';
import { getInstalledSMSelectedSelector, getInstalledSMVersionsListSelector } from '../../redux-store/selectors/installedSM';

import {
  GATEWAY_LIST_URL, DATA_EXPORT_GATEWAYS_INFO_URL, DATA_EXPORT_USERS_INFO_URL,
  FIRMWARE_UPDATE_URL_DELETE, GATEWAY_URL_DELETE, GATEWAY_REBOOT_SERVICE_LOGS_URL,
  GATEWAY_USER_ACTIVITY_LOGS_URL,
  MAINTENANCE_MESSAGE_URL
} from '../../api/apiUrls';
import { ADD_GATEWAY_FORM_ID, DATA_LIST_ID } from './constants';
import columns from './columns';
import { setPagesSetting, setPagesSettingQueries } from '../PagesSettings/slice';
import { signalOptions, filteredListOfOptions, releaseGroups } from '../../components/AdditionalDataListFilters/constants';
import i18n from '../../i18n';

import '../Users/index.scss';
import { addNotification } from '../NotificationGenerator/slice';
import { transformResponse } from '../../utils/transformResponse';

const CheckboxTable = checkboxHOC(ReactTable);

/**
 * @module InstalledSM
 */
/**
 * Renders Installed SM page
 * @memberof module:InstalledSM
 */
class InstalledSM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      deviceTypeOptions: [],
      deviceNameOptions: []
    };
    const { myRoleType, pagesSettings: { [DATA_LIST_ID]: pageSettings } } = this.props;
    this.columns = columns(myRoleType, pageSettings);
    this.selectAll = true;
  }

  componentDidMount() {
    const {
      instSMGetFWVersions: instSMGetFWVersionsAC,
      requestCloudStatusMessage: requestCloudStatusMessageFromProps,
      devicesGetTypesAndNames: devicesGetTypesAndNamesFromProps,
      myRoleType
    } = this.props;

    instSMGetFWVersionsAC();
    requestCloudStatusMessageFromProps({ roleType: myRoleType, language: i18n.language });
    devicesGetTypesAndNamesFromProps({
      deviceType: 'all',
      cb: (data) => {
        this.setState({
          deviceTypeOptions: [...uniqBy(data.map(({ type }) => ({ type })), 'type'), { type: 'Sub-Meter' }],
          deviceNameOptions: uniqBy(data.map(({ name }) => ({ name })), 'name')
        });
        return saveDevicesTypesList({ data });
      }
    });
  }

  UNSAFE_componentWillUpdate() {
    this.selectAll = true;
  }

  /**
   * Export all users
   */
  exportCSV = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest(
      {
        method: 'get',
        url: DATA_EXPORT_USERS_INFO_URL,
        config: undefined,
        onResult: { errorMessage: 'exportRequestError', successMessage: 'success' }
      });
  };

  exportLogFile = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest(
      {
        method: 'post',
        url: GATEWAY_REBOOT_SERVICE_LOGS_URL,
        config: undefined,
        onResult: { errorMessage: 'exportRequestError', successMessage: 'success' }
      });
  };

  logsReportExportFile = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest(
      {
        method: 'post',
        url: GATEWAY_USER_ACTIVITY_LOGS_URL,
        config: undefined,
        onResult: { errorMessage: 'exportRequestError', successMessage: 'success' }
      });
  };

  /**
   * Export all devices
   */
  exportAllDevicesCSV = () => {
    const { installedSMSendRequest: sendRequest } = this.props;
    sendRequest(
      {
        method: 'get',
        url: DATA_EXPORT_GATEWAYS_INFO_URL,
        config: undefined,
        onResult: { errorMessage: 'exportRequestError', successMessage: 'success' }
      });
  };

  removeFirmwareUpdate = ({ id }) => {
    const { installedSMSendRequest: sendRequest, dataListReloadData: dataListReloadDataProps } = this.props;
    sendRequest(
      {
        method: 'DELETE',
        url: `${FIRMWARE_UPDATE_URL_DELETE}/${id}`,
        config: undefined,
        onResult: {
          successMessage: 'removeFirmwareRequestSuccessful',
          errorMessage: 'removeFirmwareRequestError',
          cb: () => dataListReloadDataProps({ listID: 'installed_sm' })
        },
        modalID: REMOVE_FIRMWARE_UPDATE_MODAL_ID
      });
  };

  confirmRemoveGateway = (value) => {
    const {
      closeModalWindow: closeModal,
      openModalWindow: openModal
    } = this.props;

    closeModal({ modalID: CONFIRM_REMOVE_GATEWAY_MODAL_ID });
    openModal({ modalID: REMOVE_GATEWAY_MODAL_ID, data: { id: value } });
  };

  removeGateway = ({ id }) => {
    const { installedSMSendRequest: sendRequest, dataListReloadData: dataListReloadDataProps, addNotification: addNotificationFromProps } = this.props;
    sendRequest(
      {
        method: 'DELETE',
        url: `${GATEWAY_URL_DELETE}/${id}`,
        config: undefined,
        onResult: {
          successMessage: 'removeGatewayRequestSuccessful',
          cb: (response) => {
            if (response.error) {
              addNotificationFromProps({ type: 'error', text: response.message });
            }
            dataListReloadDataProps({ listID: 'installed_sm' });
          }
        },
        modalID: REMOVE_GATEWAY_MODAL_ID
      }
    );
  };

  transformGatewayFormFieldsForApi = (fields) => ({
    ...fields,
    gateways: fields.gateways
      .split(/[\s\,]+/)
      .filter((gateway) => gateway)
      .join(',')
  });

  addGateway = (fields) => {
    const {
      modalWindowSend: modalSend
    } = this.props;
    return modalSend(
      {
        modalID: ADD_GATEWAY_FORM_ID,
        requestConfig: {
          method: 'post',
          url: GATEWAY_LIST_URL,
          data: this.transformGatewayFormFieldsForApi(fields)
        }
      });
  };

  addCloudStatusMessage = (fields) => {
    const { maintenance_message, extended, maintenance_message_extended } = fields;
    const {
      modalWindowSend: modalSend,
      updateCloudStatusMessage: updateCloudStatusMessageFromProps
    } = this.props;

    modalSend(
      {
        modalID: CLOUD_STATUS_MODAL_ID,
        requestConfig: {
          method: 'post',
          url: `${MAINTENANCE_MESSAGE_URL}?extended=${extended}`,
          data: { message: extended ? maintenance_message_extended : maintenance_message }
        }
      });
    if (maintenance_message !== undefined || maintenance_message_extended !== undefined) {
      updateCloudStatusMessageFromProps(
        {
          message: maintenance_message || maintenance_message_extended || i18n.t('maintenance_message'),
          extended
        }
      );
    }
  };

  /**
   * Toggles selection for a row
   * @param {number} key_ ID of row
   */
  toggleSelection = (key_) => {
    const key = key_.replace('select-', '');
    const {
      selectedRows,
      installedSMReplaceSelected: installedSMReplaceSelectedProps
    } = this.props;
    let selection = [...selectedRows];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
      if (this.selectAll) this.selectAll = false;
    } else {
      selection.push(key);
      if (!this.selectAll) this.selectAll = true;
    }
    installedSMReplaceSelectedProps({ newSelected: selection });
  };

  /**
   * Toggles selection for all rows
   */
  toggleAll = () => {
    const {
      selectedRows,
      installedSMReplaceSelected: installedSMReplaceSelectedProps
    } = this.props;
    const { selectAll: selectedAll } = this.state;
    const selectAll = !selectedAll;
    const selection = [...selectedRows];

    const wrappedInstance = this.checkboxTable.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    if (selectAll) {
      currentRecords.forEach((item) => {
        const index = selection.indexOf(item._original._id);
        if (index === -1) {
          selection.push(item._original._id);
        }
      });
    } else {
      currentRecords.forEach((item) => {
        const index = selection.indexOf(item._original._id);
        if (index !== -1) {
          selection.splice(index, 1);
        }
      });
    }
    installedSMReplaceSelectedProps({ newSelected: selection });
    this.setState({ selectAll });
  };

  /**
   * Checks if row is selected
   * @returns {boolean}
   */
  isSelected = (key) => {
    const { selectedRows } = this.props;
    return selectedRows.includes(key);
  };

  /**
   * update device name options
   * @param {string[] } data
   * @returns {{name: string}[]}
   */
  extraUpdateDeviceNameOptions = (data) => {
    const { devicesList = [], allDataList } = this.props;
    const deviceNameSubmitParams = allDataList?.installed_sm?.params?.device_names || '';
    const filteredNameOptions = devicesList.filter(({ type }) => data.split(',').includes(type)).map(({ name }) => ({ name }));
    const subMeterDevices = data.includes('Sub-Meter') ? devicesList.filter(({ hasSubmeter }) => hasSubmeter).map(({ name }) => ({ name })) : [];
    const filteredNameOptionsWithSubMeter = uniqBy([...filteredNameOptions, ...subMeterDevices], 'name');

    this.setState({
      deviceNameOptions: filteredNameOptionsWithSubMeter
    });

    return {
      device_names: deviceNameSubmitParams?.split(',')
        .filter((name) => filteredNameOptionsWithSubMeter.map((props) => props.name).includes(name)).join(',') || ''
    };
  };

  resetFilters(filters) {
    const {
      setPagesSettingQueries: setPagesSettingQueriesFromProps,
      dataListSendRequest: dataListSendRequestFromProps,
      pagesSettings: { [DATA_LIST_ID]: pageSettings }
    } = this.props;

    setPagesSettingQueriesFromProps({ page: DATA_LIST_ID, data: filters });
    dataListSendRequestFromProps({
      listID: DATA_LIST_ID,
      dataList: {
        transformResponse,
        listURL: GATEWAY_LIST_URL,
        params: filterOnUndefined({ ...pageSettings.queries, ...filters })
      }
    });
  }

  refetchDataList() {
    const {
      dataListSendRequest: dataListSendRequestFromProps,
      pagesSettings: { [DATA_LIST_ID]: pageSettings }
    } = this.props;

    dataListSendRequestFromProps({
      listID: DATA_LIST_ID,
      dataList: {
        transformResponse,
        listURL: GATEWAY_LIST_URL,
        params: filterOnUndefined(pageSettings.queries)
      }
    });
  }

  toggleFilers(skipReload) {
    const { setPagesSetting: setPagesSettingFromProps, pagesSettings: { [DATA_LIST_ID]: pageSettings } } = this.props;
    setPagesSettingFromProps({ page: DATA_LIST_ID, data: { isFilteredTabExpanded: !pageSettings.isFilteredTabExpanded } });

    if (pageSettings.isFilteredTabExpanded && !skipReload) {
      const filterOptions = omit(pageSettings.queries, ['_sort', '_limit', '_start', 'fields', 'sm_id_contains']);
      this.resetFilters(Object.keys(filterOptions).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}));
    }
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;
    const {
      selectedRows,
      versionsList,
      openModalWindow: openModal,
      cloudStatusInfo = {},
      myRoleType,
      pagesSettings: { [DATA_LIST_ID]: pageSettings }
    } = this.props;

    const checkboxProps = {
      selectWidth: 45,
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTdProps: () => ({
        className: 'checkbox-react-table'
      }),
      getTrProps: (allComponentProps, currentProps) => {
        if (!allComponentProps || !currentProps) return {};
        const { selectAll: selectedAll } = this.state;
        const selected = this.isSelected(currentProps.original._id);
        if (this.selectAll && !selected) {
          this.selectAll = false;
        }

        if (allComponentProps.data.length - 1 === currentProps.index) {
          // if it's the last row
          if (this.selectAll && !selectedAll) {
            this.setState({ selectAll: true });
          } else if (!this.selectAll && selectedAll) {
            this.setState({ selectAll: false });
          }
        }

        return {
          style: {
            backgroundColor: selected ? '#e8e8e8' : 'inherit'
          }
        };
      },
      getTheadThProps: () => ({
        className: 'checkbox-react-table-all'
      })
    };

    const menuItems = [
      ...(['root', 'solar_admin'].includes(myRoleType)) ? [
        {
          onClick: this.exportAllDevicesCSV,
          value: 'exportAllDevices',
          key: 'exportAllDevices'
        }
      ] : [],
      {
        onClick: this.exportCSV,
        value: 'exportBtn',
        key: 'exportBtn'
      },
      {
        onClick: this.exportLogFile,
        value: 'exportLogBtn',
        key: 'exportLogBtn'
      },
      ...(['root', 'solar_admin'].includes(myRoleType)) ? [
        {
          onClick: this.logsReportExportFile,
          value: 'logsReportExport',
          key: 'logsReportExport'
        }
      ] : []
    ];

    const filterOptions = omit(pageSettings.queries, ['_sort', '_limit', '_start', 'fields']);

    const isFiltersActive = Object.keys(filterOptions).some((key) => Boolean(pageSettings.queries[key]));

    return (
      <div className="main-table-container">
        <ConfirmDeleteGatewayWindow handleSubmit={this.confirmRemoveGateway} />
        <DeleteFirmwareWindow onSubmit={this.removeFirmwareUpdate} />
        <CloudStatusWindow onSubmit={this.addCloudStatusMessage} />
        <DeleteGatewayWindow onSubmit={this.removeGateway} />
        <AddGatewayWindow onSubmit={this.addGateway} />
        <ChooseLicenseWindow type="initial" />

        <TopBar updateButton showNavigation />
        <div className="container-fluid px-0">
          <div className="px-4">
            <Breadcrumbs crumbs={[{ name: i18n.t('installedSM') }]} />
          </div>

          <div className="m-form m-form--label-align-right m--margin-top-20 my-4">
            <UpdateSMForm refetchDataList={this.refetchDataList.bind(this)} />
            <div className="d-flex w-full align-items-center column-gap-10 row-gap-5 flex-wrap my-4 px-4">
              <div className="search-field">
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.sm_id_contains,
                    searchByFields: 'sm_id,company,firmware,hardware',
                    placeholder: i18n.t('searchAllTablesElements'),
                    singleSearch: 'sm_id_contains',
                    classes: 'm-0'
                  }}
                  listID={DATA_LIST_ID}
                  listURL={GATEWAY_LIST_URL}
                  transformResponse={transformResponse}
                />
              </div>
              <div className="flex-fill">
                <button
                  type="button"
                  onClick={this.toggleFilers.bind(this, !isFiltersActive)}
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn filter"
                >
                  <i className="fa fal fa-filter mr-2" />
                  {i18n.t('filters')}
                </button>
              </div>
              <ExportDropDownList menuItems={menuItems} />
              <button
                type="button"
                onClick={openModal.bind(null, { modalID: ADD_GATEWAY_FORM_ID, data: null })}
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst white btn-comp exportBtn"
              >
                {i18n.t('addGatewayBtn')}
              </button>
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  onClick={openModal.bind(null, { modalID: CLOUD_STATUS_MODAL_ID, data: cloudStatusInfo })}
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst white btn-comp exportBtn"
                >
                  {i18n.t('cloudStatus')}
                </button>
              </div>
              <div className="d-flex align-items-center">
                <span>
                  <span className="smu-select-sp-num">
                    {selectedRows ? selectedRows.length : 0}
                    &nbsp;
                  </span>
                  <span className="smu-select-sp">
                    {i18n.t('SMUSelected')}
                  </span>
                </span>
              </div>
            </div>

            <Expander expanded={pageSettings.isFilteredTabExpanded}>
              <div className="additional-filters__container px-4 installed-sm-expander-filters align-items-start">
                <div className="d-flex align-items-center">
                  <div className="form-group m-form__group version-field">
                    <DataListFilterV2
                      Component={RenderSelectFilter}
                      listID={DATA_LIST_ID}
                      listURL={GATEWAY_LIST_URL}
                      transformResponse={transformResponse}
                      componentProps={{
                        label: 'signal',
                        filteredListOfOptions: signalOptions,
                        fieldName: 'signal'
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="form-group m-form__group version-field">
                    <DataListFilterV2
                      Component={RenderSelectFilter}
                      listID={DATA_LIST_ID}
                      listURL={GATEWAY_LIST_URL}
                      transformResponse={transformResponse}
                      componentProps={{
                        label: 'error',
                        filteredListOfOptions,
                        fieldName: 'hasErrors'
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="multi-select align-self-start">
                    <DataListFilterV2
                      Component={MultiFilter}
                      listID={DATA_LIST_ID}
                      listURL={GATEWAY_LIST_URL}
                      transformResponse={transformResponse}
                      componentProps={{
                        defaultSelectedOptions: pageSettings.queries.releaseGroup,
                        options: releaseGroups,
                        placeholder: i18n.t('selectReleaseGroup'),
                        title: i18n.t('releaseGroup'),
                        fieldName: 'value',
                        submitFieldName: 'releaseGroup'
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="multi-select align-self-start">
                    <DataListFilterV2
                      Component={MultiFilter}
                      componentProps={{
                        defaultSelectedOptions: pageSettings.queries.firmware,
                        options: versionsList,
                        placeholder: i18n.t('selectFirmwareVersion'),
                        title: i18n.t('firmwareVersion'),
                        fieldName: 'version',
                        submitFieldName: 'firmware'
                      }}
                      listID={DATA_LIST_ID}
                      listURL={GATEWAY_LIST_URL}
                      transformResponse={transformResponse}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="multi-select align-self-start">
                    <DataListFilterV2
                      Component={LicenseFilter}
                      componentProps={{
                        settings: { ...pageSettings, columns: { license: true } }
                      }}
                      listID={DATA_LIST_ID}
                      listURL={GATEWAY_LIST_URL}
                      transformResponse={transformResponse}
                    />
                  </div>
                </div>
                <div className="multi-select align-self-start">
                  <DataListFilterV2
                    Component={MultiFilter}
                    componentProps={{
                      defaultSelectedOptions: pageSettings.queries.types,
                      options: this.state.deviceTypeOptions,
                      placeholder: i18n.t('selectType'),
                      title: i18n.t('deviceType'),
                      fieldName: 'type',
                      submitFieldName: 'types',
                      extraUpdate: this.extraUpdateDeviceNameOptions
                    }}
                    listID={DATA_LIST_ID}
                    listURL={GATEWAY_LIST_URL}
                    transformResponse={transformResponse}
                  />
                </div>
                <div className="multi-select align-self-start">
                  <DataListFilterV2
                    Component={MultiFilter}
                    componentProps={{
                      defaultSelectedOptions: pageSettings.queries.device_names,
                      options: this.state.deviceNameOptions,
                      placeholder: i18n.t('selectName'),
                      title: i18n.t('deviceName'),
                      submitFieldName: 'device_names',
                      fieldName: 'name',
                      disabled: !pageSettings.queries.types
                    }}
                    listID={DATA_LIST_ID}
                    listURL={GATEWAY_LIST_URL}
                    transformResponse={transformResponse}
                  />
                </div>
                <div className={`additional-filters__reset-btn ${!isFiltersActive && 'disable-button'} align-self-center ml-auto`}>
                  <PrimaryButton
                    onClickHandler={this.resetFilters.bind(this, Object.keys(filterOptions).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}))}
                    i18nLabel="resetFilters"
                    disabled={!isFiltersActive}
                    isLargeButton
                  />
                </div>
              </div>
            </Expander>
          </div>

          <div className="px-4 table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded installed-table">
            <DataListV2
              listID={DATA_LIST_ID}
              listURL={GATEWAY_LIST_URL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={CheckboxTable}
              componentProps={{
                ref: (r) => {
                  this.checkboxTable = r;
                  return r;
                },
                ...checkboxProps,
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns: this.columns,
                manual: true
              }}
            />
            <DataListPagination
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

InstalledSM.propTypes = {
  myRoleType: PropTypes.string.isRequired,
  instSMGetFWVersions: PropTypes.func.isRequired,
  installedSMReplaceSelected: PropTypes.func.isRequired,
  installedSMSendRequest: PropTypes.func.isRequired,
  closeModalWindow: PropTypes.func.isRequired,
  versionsList: PropTypes.instanceOf(Array).isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  cloudStatusInfo: PropTypes.instanceOf(Object),
  openModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  dataListReloadData: PropTypes.func.isRequired,
  requestCloudStatusMessage: PropTypes.func.isRequired,
  updateCloudStatusMessage: PropTypes.func.isRequired,
  devicesGetTypesAndNames: PropTypes.func.isRequired,
  devicesList: PropTypes.instanceOf(Array),
  allDataList: PropTypes.instanceOf(Object).isRequired,
  pagesSettings: PropTypes.instanceOf(Object).isRequired,
  setPagesSetting: PropTypes.func.isRequired,
  setPagesSettingQueries: PropTypes.func.isRequired,
  dataListSendRequest: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  selectedRows: getInstalledSMSelectedSelector,
  versionsList: getInstalledSMVersionsListSelector,
  cloudStatusInfo: getCloudStatusMessagesSelector,
  user: getSignInUserSelector,
  devicesList: getUserDevicesDevicesListSelector,
  searchParams: getDataListInstalled_smParamsSelector,
  allDataList: getDataListSelector,
  pagesSettings: getPagesSettingsSelector
});

export default connect(
  mapStateToProps,
  {
    openModalWindow,
    dataListReloadData,
    closeModalWindow,
    modalWindowSend,
    installedSMReplaceSelected,
    instSMGetFWVersions,
    installedSMSendRequest,
    updateCloudStatusMessage,
    requestCloudStatusMessage,
    devicesGetTypesAndNames,
    setPagesSetting,
    setPagesSettingQueries,
    dataListSendRequest,
    addNotification
  }
)(InstalledSM);
